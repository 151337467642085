import React, { useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTop from "../../components/globalTop"
import ModalRegister from "../../components/modalRegister"

const Dietetyka = () => {
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)

  return (
    <Layout>
      <SEO seoTitle="Dietetyka" description="Dietetyka" />

      <GlobalTop title="Dietetyka" offer />

      <section className="offer-single-content">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6">
              <h2 className="section--header">
                Kim jest
                <strong>dietetyk kliniczny?</strong>
              </h2>
              <p>
                Dietetyk kliniczny to specjalista w dziedzinie dietetyki, który
                skupia się na zastosowaniu zasad odżywiania w celu leczenia i
                zapobiegania chorobom. Dietetyk kliniczny ocenia stan odżywienia
                pacjentów, tworzy i dostosowuje indywidualne plany żywieniowe,
                aby wspierać leczenie i poprawiać stan zdrowia. Zajmuje się
                pacjentami z różnymi schorzeniami, takimi jak cukrzyca, choroby
                serca, zaburzenia odżywiania, otyłość, a także wspiera pacjentów
                w okresie rekonwalescencji po operacjach. Dietetyk kliniczny
                pełni również rolę edukacyjną, ucząc pacjentów i ich rodziny o
                zdrowych nawykach żywieniowych i zarządzaniu dietą w ramach
                profilaktyki i terapii.
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/dietetyka/dietetyka-image-1.png")}
                alt=""
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../../assets/images/offer/dietetyka/dietetyka-image-2.png")}
                alt=""
              />
            </div>
            <div className="col-md-5 offset-md-1">
              <h2 className="section--header">
                Kiedy warto skorzystać z usług
                <strong>dietetyka?</strong>
              </h2>
              <p>Warto skorzystać z usług dietetyka w sytuacjach takich jak:</p>
              <ul>
                <li>
                  Potrzeba zmiany diety w celu poprawy ogólnego stanu zdrowia,
                  zwiększenia energii lub poprawy samopoczucia
                </li>
                <li>
                  Chęć skutecznej kontroli wagi, zarówno w przypadku chęci
                  zrzucenia zbędnych kilogramów, jak i zdrowego przybrania na
                  wadze.
                </li>
                <li>
                  Diagnoza chorób, które wymagają specjalnej diety, takich jak
                  cukrzyca, choroby sercowo-naczyniowe, nietolerancje pokarmowe
                  czy zaburzenia odżywiania.
                </li>
                <li>
                  Potrzeba wsparcia w planowaniu posiłków i zdrowego żywienia
                  dla osób z aktywnym trybem życia, sportowców czy dzieci i
                  młodzieży w okresie wzrostu.
                </li>
                <li>
                  Pragnienie nauki o zdrowym żywieniu, czytaniu etykiet
                  żywnościowych i dokonywaniu lepszych wyborów żywieniowych dla
                  siebie i rodziny.
                </li>
                <li>
                  Konieczność dostosowania diety do specjalnych warunków
                  zdrowotnych, jak ciąża, menopauza, choroby przewlekłe, alergie
                  pokarmowe.
                </li>
              </ul>
              <p>
                <span className="purple-text">
                  Konsultacje z dietetykiem mogą zapewnić indywidualnie
                  dopasowane porady żywieniowe, które pomogą osiągnąć cele
                  zdrowotne i poprawić jakość życia.
                </span>
              </p>
              <button
                className="btn-custom btn-custom--purple"
                onClick={() => setIsRegisterOpen(true)}
              >
                Umów się
              </button>
            </div>
          </div>
        </div>
      </section>

      <ModalRegister
        isOpen={isRegisterOpen}
        onClose={() => setIsRegisterOpen(false)}
      />
    </Layout>
  )
}

export default Dietetyka
